import React from 'react';
import '../styles/LandingPage.css';

const LandingPage = () => {
    return (
        <div>
             {/* <section id="notis">
                <div className="news-card">
                    <h2>Tillfällig stängning</h2>
                    <p>Restaurangen håller stängt torsdagen den 13 februari, fredagen den 14 februari och lördagen den 15 februari på grund av personalbrist. Vi beklagar eventuella besvär och tackar för er förståelse.</p>
                </div>
            </section> */}
            <section id="welcome">
                <div className="container">
                    <h2>Välkommen!</h2>
                    <p>Upptäck vår tropiska meny inspirerad av rika smaker och färska ingredienser. Vi serverar rätter som ger en känsla av värme och gemenskap – allt tillagat med kärlek av vår erfarna köksmästare.</p>
                </div>
            </section>

            <section id="allergies">
                <div className="container">
                    <h2>Allergier och Specialkost</h2>
                    <p>
                        Vi vill att alla våra gäster ska känna sig välkomna och trygga när de äter hos oss. Om du eller någon i ditt sällskap har allergier eller specialkost, vänligen meddela oss i förväg. Vi gör vårt bästa för att anpassa oss efter dina behov.
                    </p>
                    <p>
                        Kontakta oss direkt på <strong>072-16 16 790</strong> eller prata med vår personal på plats för mer information om våra ingredienser och rätter.
                    </p>
                </div>
            </section>


            <section id="location">
                <div className="container">
                    <h2>Hitta Oss</h2>
                    <div className="location-wrapper">
                        <div className="location-section">
                            <h3>Adress</h3>
                            <p>Sankt Persgatan 22,</p>
                            <p>602 33 Norrköping.</p>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2079.491563323264!2d16.173122176693262!3d58.58724977019863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46593b004dabd153%3A0x1784a60a7dd14374!2sTanjay!5e0!3m2!1sen!2sse!4v1737326178816!5m2!1sen!2sse"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Google Map"
                            ></iframe>
                            <p>Endast tre minuters promenad från spårvagnshållplatsen Västertull.</p>
                        </div>
                        <div className="location-section">
                            <h3>Öppettider</h3>
                            <p>
                                Måndag–Torsdag: 11:00 – 20:00<br />
                                Fredag: 11:00 – 21:00<br />
                                Lördag: 14:00 – 21:00<br />
                                Söndag: Stängt
                            </p>
                        </div>
                        <div className="location-section">
                            <h3>Parkering</h3>
                            <p>Det finns 30 minuters parkering precis utanför oss.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact">
                <div className="container">
                    <h2>Kontakta Oss</h2>
                    <p>
                        <strong>Email:</strong> <a href="mailto:info@tanjay.se">info@tanjay.se</a><br />
                        <strong>Telefon:</strong> <strong>072 16 16 790</strong>
                    </p>
                </div>
            </section>

            <section id="booking">
                <div className="container">
                    <h2>Boka Bord</h2>
                    <p>
                        Vi har en begränsad sittkapacitet med totalt 10 platser. För gäster som reser från andra städer och vill äta på plats, rekommenderar vi att ni ringer oss i förväg. På så sätt kan vi säkerställa att ni får en plats. Kontakta oss på <strong>072-16 16 790</strong>.
                    </p>
                </div>
            </section>

        </div>
    );
};

export default LandingPage;
