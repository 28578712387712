const menuData = {
  forratter: {
    visName: "Förrätter",
    items: [
    { id: 1, name: "F1: PAPADAM", description: "2 st frasiga bröd (som stora chips) med mango chutney", price: 29, },
    { id: 2, name: "F2: SAMOSA VEG/KÖTT", description: "Friterade grönsakspiroger, serveras med yoghurtsås och sallad", price: "39/49", },
    { id: 3, name: "F3 PAKORA VEG", description: "Friterade grönsaksbollar med kikärtsmjöl, serveras med yoghurtsås och sallad", price: 39, },
    { id: 4, name: "F4: VÅRRULLE", description: "Friterade grönsakspiroger, serveras med yoghurtsås och sallad", price: 39, },
    { id: 5, name: "F5: KYCKLINGVINGAR", description: "Marinerade i soya, tandoori masala och vitlök", price: 49, }
  ]
},
  brod: {
    visName: "Bröd",
    items:[
    { id: 7, name: "B1: CHAPATI", description: "Indiskt tunnbröd gjort på rågmjöl", price: 30, },
    { id: 8, name: "B2: ALOO PARATHA", description: "Smörstekt bröd med inbakad potatis", price: 39, },
  ]},
  kycklingRatter: {
    visName: "Kycklingrätter",
    items:[
    { id: 9, name: "K1: KYCKLING CURRY", description: "Kyckling i currysås med färsk tomat", price: 159, },
    { id: 10, name: "K2: KYCKLING TIKKA MASALA", description: "Grillad kycklingfilé i gryta tillagad med crème fraiche, tomat och smörfrästa kryddor", price: 169, },
    { id: 11, name: "K3: KYCKLING KORMA", description: "Delikat kycklinggryta tillagad med yoghurt, mandel, kokos, smör och grädde", price: 169, },
    { id: 13, name: "K4: KYCKLING KARAHI🌶️", description: "Lerugnsgrillad kycklingfilé tillagad med färska tomater, grön, röd paprika, koriander och färsk mynta", price: 169, },
    { id: 14, name: "K5: KYCKLING VINDALOO🌶️🌶️🌶️", description: "Kyckling med stark sås med färsk chili och tomatpure", price: 169, },
    { id: 15, name: "K6: KYCKLING DOPIAZAH🌶️", description: "Kycklinggryta med färsk tomat, lök, paprika i fyllig kryddig sås", price: 159, },
    { id: 16, name: "K7: KYCKLING SPENAT", description: "Kycklingfilé och spenat i currysås med färsk tomat, ingefära, garam masala", price: 169, },
    { id: 17, name: "K8: KYCKLING BALTI🌶️🌶️", description: "Grillad kycklingfilé tillagad med söt-sur stark baltisås med tomat, paprika och lök", price: 169, },
    { id: 18, name: "K9: KYCKLING SAMBAL🌶️", description: "Kycklinggryta tillagad med speciella Indiska kryddor, söt-sur-stark smak", price: 169, },
    { id: 19, name: "K10: KYCKLING MADRAS🌶️", description: "Stekt kyckling marinerad med yoghurt och olika kryddor, färsk koriander, stark chili och kokos", price: 169, }
  ]
},
  tanSizlar: {
    visName: "Sizlar",
    items:[
    { id: 20, name: "T1: KYCKLING TIKKA SIZLAR", description: "Kycklingklubbor marinerade i traditionell tandoori masala, tillagad i electriskt ugn", price: 159, },
    { id: 21, name: "T2: KYCKLING SIZLAR", description: "Kycklingfilé marinerad i yoghurt med aromatiska kryddor", price: 169, },
    { id: 22, name: "T3: GARLIC KYCKLING SIZLAR", description: "Kycklingfilé marinerad i färsk vitlök", price: 169, },
    { id: 23, name: "T4: LAMM SIZLAR", description: "Lammfilé marinerad i yoghurt med traditionell tandoori masala", price: 199, },
    { id: 24, name: "T5: MIX SIZLAR", description: "Kombination av chicken sizlar, lamm sizlar, garlic chicken sizlar och seekh kebab", price: 199, },
    { id: 25, name: "T6: SALMON TIKKA SIZLAR", description: "Laxfilé marinerad i yoghurt, ingefära, vitlök, örter, citron och garam masala", price: 189, },
  ]},

  Lamm: {
    visName: "Lamm rätter",
    items:[
    { name: "L1: LAMM CURRY", price: 159, description: "Lamm i currysås med färsk tomat" },
    { name: "L2: LAMM BUTTER MASALA", price: 179, description: "Grillad lammfilé i gryta tillagad med crème fraiche, tomat och smörstarka kryddor" },
    { name: "L3: LAMM KORMA", price: 169, description: "Delikat lammgryta tillagad med grädde, mandelnötter och smör" },
    { name: "L4: LAMM KARAHI🌶️🌶️", price: 169, description: "Lammstek tillagad med färska tomater, paprika, mynta och koriander", "spicy": "🌶️" },
    { name: "L5: LAMM SPENAT", price: 169, description: "Lammrätter tillagad med spenat och färska kryddor" },
    { name: "L6: LAMM BALTI", price: 169, description: "Grillad lammfilé tillagad med sur och stark baltisås och med tomat, paprika och lök", "spicy": "🌶️" },
    { name: "L7: LAMM SAMBAL🌶️", price: 169, description: "Lammgryta tillagad med speciella indiska kryddor, söt-sur stark smak", "spicy": "🌶️" },
    { name: "L8: LAMM VINDALOO🌶️🌶️🌶️", price: 169, description: "Grillad lammfilé tillagad med sur och stark baltisås och med tomat, paprika och lök", "spicy": "🌶️🌶️🌶️" },
    { name: "L9:ROGHAN JOSH", price: 159, description: "Lammstek gryta tillagad med sesam, yoghurtsås och blandad med färsk koriander" }
  ]},
  Biryani: {
    visName: "Briyani",
    items:[
    { name: "BN1: JHINGA BIRYANI", price: 169, description: "Små räkor blandade med indiskt basmatiris, färsk koriander, färsk mynta, stekt lök smaksatt med saffran" },
    { name: "BN3: KYCKLING BIRYANI", price: 169, description: "Kyckling och ris smaksatt med saffran och en blandning av olika kryddor" },
    { name: "BN2: VEGETARISK BIRYANI", price: 159, description: "Grönsaker blandade med indiskt basmatiris, färsk koriander, mynta smaksatt med saffran" },
    { name: "BN4: LAMM BIRYANI", price: 179, description: "Lamm och ris smaksatt med saffran och en blandning av olika kryddor" }
  ]},
  fisk_och_rakor: {
    visName: "Fisk och räkor",
    items:[
    { name: "FR1: FISK CURRY", price: 159, description: "Laxfilé tillagad i currysås" },
    { name: "FR2: FISK KARAHI 🌶️🌶️", price: 169, description: "Laxfilé serveras med tomat, grön och röd paprika, mynta, färsk koriander och chili" },
    { name: "FR3: STORA RÄKOR KARAHI 🌶️🌶️", price: 169, description: "Stora räkor serveras med tomat, grön och röd paprika, mynta, färsk koriander och chili" },
    { name: "FR4: SMÅ RÄKOR CURRY", price: 159, description: "Små räkor i currysås med färsk tomat" },
    { name: "FR5: STORA RÄKOR CURRY", price: 169, description: "Stora räkor i currysås med färsk tomat" },
    { name: "FR6: RÄKOR KORMA", price: 159, description: "Delikata räkor i gryta tillagad med yoghurt, nötter, smör och grädde" },
    { name: "FR7: RÄKOR SPENAT", price: 159, description: "Räkor tillagad med bladspenat och färska kryddor" },
    { name: "FR8: RÄKOR SABZI", price: 159, description: "Räkor med grönsaker och färska kryddor" },
    { name: "FR9: SMÅ RÄKOR VINDALOO 🌶️🌶️🌶️", price: 159, description: "Små räkor med extra stark sås, färsk tomat och lök" },
    { name: "FR10: STORA RÄKOR VINDALOO 🌶️🌶️🌶️", price: 169, description: "Stora räkor med extra stark sås, färsk tomat och lök" }
  ]},
  Veg: {
    visName: "Vegetariska rätter",
    items:[
    { name: "V1: ALO GOBI 🥦", price: 159, description: "Potatis och blomkål tillagad på indiskt vis" },
    { name: "V2: MIX SABZI 🥦", price: 139, description: "Blandade grönsaker i sås med klassiska indiska kryddor" },
    { name: "V3: PALAK PANIR 🥦🧀", price: 159, description: "Indisk hemgjord ost stekt och kokt i currysås med spenat" },
    { name: "V4: TARKA DAAL 🥦", price: 149, description: "Kokta linser med smör och indiska kryddor" },
    { name: "V5: PANIR BUTTER MASALA 🥦🧀", price: 169, description: "Smörstekt hemgjord ost med yoghurt och tikka masalasås" },
    { name: "V6: CHANA MASALA 🥦", price: 159, description: "Potatis fylld med russin, grönsaker och indisk sås" },
    { name: "V7: MALAI KOFTA 🥦🧀", price: 159, description: "Nötter, russin, grönsaker och indisk gräddsås" },
    { name: "V8: SABNAM CURRY 🥦🧀", price: 159, description: "Potatis blandad med grönsaker och currysås" },
    { name: "V9: ALOO PALAK 🥦", price: 159, description: "Tomat, indiska kryddor, linser och matolja" },
    
  ]},

  notkott: {
    visName: "NötKött",
    items:[
    { name: "N1: NÖTKÖTT CURRY", price: 149, description: "Nötkött i currysås med färsk tomat" },
    { name: "N2: NÖTKÖTT SPENAT", price: 149, description: "Nötkötträtt tillagad med spenat och färska kryddor" },
    { name: "N3: ACHAR GOSTO", price: 159, description: "Biffgryta lagad med indiska achar masala med yoghurt och färsk koriander" },
    { name: "N4: NÖTKÖTT VINDALOO🌶️🌶️🌶️", price: 159, description: "Nötkött med yoghurt och extra stark sås med färsk tomat" },
    { name: "N5: NÖTKÖTT KARAHI🌶️🌶️", price: 159, description: "Nötkött tillagad med traditionella kryddor, mynta, färska tomater, grön och röd paprika och koriander" }
  ]},
  extra: {
    visName: "Extra",
    items:[
    { name: "E1: BARNPORTION", price: 120, description: "Välj mellan kyckling korma, tikka masala, vegetarisk curry, klubba, chicken nuggets och pommes" },
    { name: "E2: VITSÅS", price: 30, description: "Majonnäs, vitlök och socker" },
    { name: "E3: RAITA", price: 30, description: "Gurka, tomat, färsk koriander och mynta blandad i yoghurtssås" },
    { name: "E4: PICKEL", price: 30, description: "Välj mellan mix pickel, chili pickel och mango pickel" },
    { name: "E5: MANGO CHUTNEY", price: 30, description: "En söt-syrlig indisk sås gjord på mogen mango, kryddor och en hint av chili, perfekt som tillbehör till både varma och kalla rätter." },
    { name: "E6: EXTRA RIS", price: 30, description: "" }
  ]},
  Exotiska: {
    visName: "Efterrätt",
    items:[
    { name: "ET1: SÖT LASSI", price: 35, description: "En krämig och uppfriskande yoghurtdryck sötad med socker och smaksatt med rosvatten eller kardemumma"   },
    { name: "ET2: SALT LASSI", price: 35, description: "En syrlig yoghurtdryck kryddad med salt, spiskummin och fräscha örter för en balanserad smak"   },
    { name: "ET3: MANGO LASSI", price: 35, description: "En krämig och uppfriskande yoghurtdryck mixad med söt mango och en touch av kardemumma för en exotisk smakupplevelse" },
    { name: "ET4: CHAI", price: 35, description: "En aromatisk och värmande indisk teblandning, tillagad med svart te, mjölk, socker och en perfekt balans av kryddor som kardemumma, kanel och ingefära" }
  ]}, 
  lask : {
    visName: "Kolsyrade drycker",
    items: [
      { name: "Coca-Cola", price: 20, description: "" },
      { name: "Coca-Cola zero", price: 20, description: "" },
      { name: "Fanta Exotic", price: 20, description: "" },
      { name: "Fanta Exotic zero", price: 20, description: "" },
      { name: "Ramlösa naturell", price: 20, description: "" },
      { name: "Ramlösa citrus", price: 20, description: "" },
      { name: "Trocadero zero", price: 20, description: "" }
    ]
  },  
  lankes:{
    visName: "Lankesiska Rätter",
    items: [
      { name: "Kottu Roti🌶️🌶️", price: 199, description: "Hackad roti, grönsaker och ditt val av kyckling, lamm eller grösaker, wokat med aromatiska kryddor och kokosolja." },
      { name: "Parippu kari", price: 119, description: "En len och krämig linssoppa kokad med kokosmjölk, curryblad och milda kryddor." },
      { name: "Kyckling kari🌶️🌶️🌶️", price: 149, description: "En mustig kycklinggryta tillagad med kokosmjölk, kardemumma och curryblad." },
      { name: "Hoppers (Appam)", price: 49, description: "2st traditionella lankesiska pannkakor formade som skålar, med en krispig kant och en mjuk mitt." },
      { name: "String Hoppers (Idiyappam)", price: 99, description: "10 st Mjuka nudelliknande bröd som ångas och serveras med mild gul kokoscurry." },
    ]}
    
};

export default menuData;