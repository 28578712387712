import React, { useState } from 'react';
import menuData from '../data/menuData';
import Dagens from './Dagens';
import '../styles/Menu.css'; // Import the CSS file

const Menu = () => {
  const [selectedCategory, setSelectedCategory] = useState('forratter');
  const categoryNames = Object.keys(menuData);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    document.getElementById(category).scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    document.querySelector('.wrapper').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      {/* Category Buttons */}
      <div className='menu-container'>
        <div className="category-buttons">
          {categoryNames.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className="category-button"
            >
              {menuData[category].visName}
            </button>
          ))}
        </div>
      </div>

      {/* Category Sections */}
      <div>
        {categoryNames.map((category) => (
          <div key={category} id={category} className="category-section">
            <div className="title-and-button">
              <h2>{menuData[category].visName}</h2>
              <button
                className="back-to-top-button"
                onClick={scrollToTop}
              >
                ↑
              </button>
            </div>
            {category === "lankes" && <p className="warning-text" >För att säkerställa tillgänglighet ber vi dig förbeställa dessa rätter senast fredagen veckan innan. De kan hämtas eller avnjutas på plats på måndagen. </p>}

            <ul>
              {menuData[category].items.map((item) => (
                <li key={item.id}>
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <p className="price">{item.price} SEK</p>
                </li>
              ))}
            </ul>

          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
