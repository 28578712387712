const dagensMeny = {
    måndag: {
      visName: "Måndag",
      price: 119,
      items: [
        { name: "KYCKLING KORMA", description: "Delikat kycklinggryta tillagad med yoghurt, mandel, kokos, smör och grädde", price: "119" },
        { name: "NÖTKÖTT CURRY", description: "Nötkött i currysås med färsk tomat", price: "119" },
        { name: "LAMM DOPIAZAH 🌶️", description: "Lammgryta med färsk tomat, lök, paprika i fyllig kryddig sås", price: "129" },
        { name: "ALOO PALAK 🥦", description: "Potatis och spenat tillagad i en mild kryddig sås med indiska smaker.", price: "109" },
        { name: "SABNAM CURRY 🥦🧀", description: "Potatis blandad med grönsaker och currysås", price: "109" }
      ]
    },
    tisdag: {
      visName: "Tisdag",
      price: 119,
      items: [
        { name: "KYCKLING SPENAT", description: "Kycklingfilé och spenat i currysås med färsk tomat, ingefära, garam masala", price: "119" },
        { name: "NÖTKÖTT KORMA", description: "Delikat nötköttgryta tillagad med yoghurt, mandel, kokos, smör och grädde", price: "119" },
        { name: "LAMM JALFREZI", description: "Lammkött tillagat med grönsaker i en kryddig, syrlig sås med chili och aromatiska kryddor", price: "129" },
        { name: "TARKA DAAL 🥦", description: "Kokta linser med smör och indiska kryddor", price: "109" },
        { name: "ALO GOBI 🥦", description: "Potatis och blomkål tillagad på indiskt vis", price: "109" }
      ]
    },
    onsdag: {
      visName: "Onsdag",
      price: 119,
      items: [
        { name: "KYCKLING TIKKA ", description: "Grillad kycklingfilé i gryta tillagad med crème fraiche, tomat och smörfrästa kryddor", price: "119" },
        { name: "NÖTKÖTT SAMBAL🌶️", description: "nötköttgryta tillagad med speciella Indiska kryddor, söt-sur-stark smak", price: "119" },
        { name: "LAMM SPENAT", description: "Lamm tillagad med spenat och färska kryddor", price: "129" },
        { name: "CHANA MASALA 🥦", description: "Potatis fylld med russin, grönsaker och indisk gräddsås", price: "109" },
        { name: "MIX SABZI 🥦", description: "Blandade grönsaker i sås med klassiska indiska kryddor", price: "109" }
      ]
    },
    torsdag: {
      visName: "Torsdag",
      price: 119,
      items: [
        { name: "KYCKLING CURRY", description: "Kyckling i currysås med färsk tomat", price: "119" },
        { name: "RÄKOR KORMA", description: "Delikata räkor i gryta tillagad med yoghurt, nötter, smör och grädde", price: "129" },
        { name: "NÖTKÖTT DOPIAZAH 🌶️", description: "Lammgryta med färsk tomat, lök, paprika i tjock kryddig sås", price: "119" },
        { name: "PALAK PANIR 🥦🧀", description: "Indisk hemgjord ost stekt och kokt i currysås med spenat", price: "109" },
        { name: "ALO GOBI 🥦", description: "Potatis och blomkål tillagad på indiskt vis", price: "109" },
      ]
    },
    fredag: {
      visName: "Fredag",
      price: 119,
      items: [
        { name: "KYCKLING SAMBAL🌶️", description: "Kycklinggryta tillagad med speciella Indiska kryddor, söt-sur-stark smak", price: "119" },
        { name: "LAMM KORMA", description: "Delikat lammgryta tillagad med grädde, mandelnötter och smör", price: "129" },
        { name: "NÖTKÖTT KARAHI🌶️🌶️", description: "Nötkött tillagad med traditionella kryddor, mynta, färska tomater, grön och röd paprika och koriander", price: "119" },
        { name: "MIX SABZI 🥦", description: "Blandade grönsaker i sås med klassiska indiska kryddor", price: "109" },
        { name: "TARKA DAAL 🥦", description: "Kokta linser med smör och indiska kryddor", price: "109" }
      ]
    }
  };
  
  export default dagensMeny;
  